<template>
  <div>
    <gmap-map
      ref='gmap'
      :center='{ lat: 12.791195, lng: 45.009239 }'
      :zoom='14'
      style='width: 100%; height: 500px'
      @tilesloaded='initializeDrawingManager'
    >
      <gmap-polygon
        v-for='(path, index) in paths'
        :key='index'
        :paths='path'
        :editable='true'
        @paths_changed="updateEdited($event, index)"
        :options='getPolygonColor(index)'
        @click='selectPolygon(index)'
        ref='polygons'
      >
      </gmap-polygon>
    </gmap-map>
    <div v-if='selectedPolygonIndex !== null' class='delete-button-container'>
      <button type='button' @click='deletePolygon'>حذف الشكل المحدد</button>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    map_paths: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      paths: [], // Initialize paths as an empty array
      drawingManager: null,
      selectedPolygonIndex: null // Track the selected polygon for deletion
    };
  },
  methods: {
    /**
     * Updates the polygon paths when they are edited.
     * @param {Object} mvcArray - MVCArray of the polygon path.
     * @param {Number} index - Index of the polygon being edited.
     */
    updateEdited(mvcArray,index) {
      if (!mvcArray) return;

      let paths = [];

      for (let i = 0; i < mvcArray.getLength(); i++) {
        let path = [];
        for (let j = 0; j < mvcArray.getAt(i).getLength(); j++) {
          let point = mvcArray.getAt(i).getAt(j);
          let lat = point.lat();
          let lng = point.lng();
          path.push({ lat, lng });
        }
        paths.push(path);
      }
      this.paths[index] = paths[0];
      this.$emit('updated', this.paths);

    },
    /**
     * Initializes the Google Maps Drawing Manager and sets up the event listener for polygon completion.
     */
    initializeDrawingManager() {
      if (this.drawingManager || !this.$refs.gmap.$mapObject) return; // Ensure the map is ready

      this.drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: ['polygon']
        },
        polygonOptions: {
          editable: true
        }
      });

      this.drawingManager.setMap(this.$refs.gmap.$mapObject);

      google.maps.event.addListener(this.drawingManager, 'polygoncomplete', (polygon) => {
        const newPath = polygon.getPath().getArray().map(point => {
          return { lat: point.lat(), lng: point.lng() };

        });
        if (Array.isArray(this.paths)) {
          this.paths.push(newPath);
          this.$emit('updated', this.paths);
        } else {
          console.error('this.paths is not an array:', this.paths);
        }
        polygon.setMap(null); // Remove the polygon from the map
      });
    },
    /**
     * Sets the selected polygon index for deletion.
     * @param {Number} index - Index of the polygon to be selected.
     */
    selectPolygon(index) {
      this.selectedPolygonIndex = index; // Set the selected polygon index
    },
    /**
     * Deletes the selected polygon and emits the `updated` event with the new paths.
     */
    deletePolygon() {
      if (this.selectedPolygonIndex !== null && Array.isArray(this.paths)) {
        this.paths.splice(this.selectedPolygonIndex, 1); // Remove the selected polygon
        this.selectedPolygonIndex = null; // Reset the selected index
        this.$emit('updated', this.paths);
      } else {
        console.error('Cannot delete polygon: selectedPolygonIndex or paths is invalid.');
      }
    },
    /**
     * Returns the color options for polygons, highlighting the selected polygon in red.
     * @param {Number} index - Index of the polygon.
     * @returns {Object} - Color options for the polygon.
     */
    getPolygonColor(index) {
      if (index == this.selectedPolygonIndex) {
        return {
          strokeColor: 'red', // Color for the selected polygon
          fillColor: 'rgba(255, 0, 0, 0.35)' // Fill color for the selected polygon
        };
      }
      return {
        strokeColor: 'black', // Default color for unselected polygons
        fillColor: 'rgba(0, 0, 0, 0.35)' // Default fill color for unselected polygons
      };
    }
  },
  created() {
    this.paths = this.map_paths.length > 0 ? this.map_paths : [[]];
  }
};
</script>

<style scoped>
/* Position the delete button so it's easily accessible */
.delete-button-container {
  position: absolute;
  bottom: 20px;
  border-radius: 10px;
  right: 20px;
  z-index: 1000; /* Ensure the button appears above the map */
}

button {
  background-color: red;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: darkred;
}
</style>
