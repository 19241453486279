<template>

  <div class='wameed-dashboard-page-content'>
    <page-header
      :title="$t('settings.company_data')"
      :paths="[{title:$t('settings.title'),link:'settings'}]"

    />

    <b-card no-body class='wameed-card'
            v-permission='[$route.meta.pagePermissions.contact]'>
      <b-card-header class='align-items-start tab-title'>
        <h5 class='text-reg-18 text-font-main'>
          {{ $t('company_details.contacts') }}
        </h5>
      </b-card-header>
      <wameed-form refs='' @onSubmit='submitContact'>
        <div slot-scope='{ invalid }'>
          <b-card-body
            class='position-relative py-5 justify-content-between px-5'
          >
            <!-- form -->
            <div class='d-flex flex-wrap py-4'>
              <b-col
                v-for='contact in contacts'
                :key='contact.id'
                cols='12'
                md='4'
              >
                <text-input
                  :id='contact.name'
                  v-model='contact.value'
                  :label="$t('company_details.form.' + contact.name)"
                  inputClasses='text-med-14 text-font-main'
                  :name='contact.name'
                  :placeholder="$t('form.text.placeholder')"
                />
              </b-col>
            </div>
          </b-card-body>
          <b-card-footer class='px-4 py-3'>
            <div class='d-flex flex-wrap justify-content-between'>
              <div class='px-4 py-3'>
                <wameed-btn
                  classes='  text-medium-16 text-white rounded-10'
                  :title="$t('common.save_edited')"
                  :disabled='invalid'
                  type='submit'
                  variant='main'
                />
              </div>
              <div class='px-4 py-3'>
                <wameed-btn
                  classes='  text-medium-16 text-font-secondary rounded-10 '
                  :title="$t('common.cancel')"
                  type='button'
                  variant='gray'
                  @onClick='goBack'
                />
              </div>
            </div>
          </b-card-footer>
        </div>
      </wameed-form>
    </b-card>
    <b-card no-body class='wameed-card'
            v-permission='[$route.meta.pagePermissions.contact]'>
      <b-card-header class='align-items-start tab-title'>
        <h5 class='text-reg-18 text-font-main'>
          {{ $t('company_details.socials') }}
        </h5>
      </b-card-header>
      <wameed-form @onSubmit='submitSocial'>
        <div slot-scope='{ invalid }'>
          <b-card-body
            class='position-relative py-5 justify-content-between px-5'
          >
            <!-- form -->
            <div class='d-flex flex-wrap py-4'>
              <b-col
                v-for='social in socials'
                :key='social.id'
                cols='12'
                md='4'
              >
                <text-input
                  :id='social.name'
                  v-model='social.value'
                  :label="$t('company_details.form.' + social.name)"
                  inputClasses='text-med-14 text-font-main'
                  :name='social.name'
                  :placeholder="$t('form.text.placeholder')"
                />
              </b-col>
            </div>
          </b-card-body>
          <b-card-footer class='px-4 py-3'>
            <div class='d-flex flex-wrap justify-content-between'>
              <div class='px-4 py-3'>
                <wameed-btn
                  classes='  text-medium-16 text-white rounded-10'
                  :title="$t('common.save_edited')"
                  :disabled='invalid'
                  type='submit'
                  variant='main'
                />
              </div>
              <div class='px-4 py-3'>
                <wameed-btn
                  classes='  text-medium-16 text-font-secondary rounded-10 '
                  :title="$t('common.cancel')"
                  type='button'
                  variant='gray'
                  @onClick='goBack'
                />
              </div>
            </div>
          </b-card-footer>
        </div>
      </wameed-form>
    </b-card>
    <b-card no-body class='wameed-card'

            v-permission='[$route.meta.pagePermissions.boundary]'
    >
      <b-card-header class='align-items-start tab-title'>
        <h5 class='text-reg-18 text-font-main'>
          {{ $t('company_details.boundary') }}
        </h5>
      </b-card-header>
      <wameed-form @onSubmit='updateBoundary'>
        <div slot-scope='{ invalid }'>
          <b-card-body
            class='position-relative py-5 justify-content-between px-5'
          >
            <!-- form -->
            <map-polygons-modal v-if='boundary!=null' :map_paths='boundary' @updated='updateBoundaryData' />
          </b-card-body>
          <b-card-footer class='px-4 py-3'>
            <div class='d-flex flex-wrap justify-content-between'>
              <div class='px-4 py-3'>
                <wameed-btn
                  classes='  text-medium-16 text-white rounded-10'
                  :title="$t('common.save_edited')"
                  :disabled='invalid'
                  type='submit'
                  variant='main'
                />
              </div>
              <div class='px-4 py-3'>
                <wameed-btn
                  classes='  text-medium-16 text-font-secondary rounded-10 '
                  :title="$t('common.cancel')"
                  type='button'
                  variant='gray'
                  @onClick='goBack'
                />
              </div>
            </div>
          </b-card-footer>
        </div>
      </wameed-form>
    </b-card>
  </div>
</template>

<script>
import PageHeader from '@/components/wameed/WameedPageHeader.vue';

import MapPolygonsModal from '@/components/mapPolygonsModal.vue';
import { WameedBtn, WameedForm, WameedTextInput as TextInput } from 'wameed-ui/dist/wameed-ui.esm';

import { mapGetters } from 'vuex';

export default {
  components: {
    MapPolygonsModal,
    PageHeader,
    TextInput,
    WameedBtn,
    WameedForm
  },
  data() {
    return {
      contacts: [],
      socials: [],
      boundary: null,
      temp_boundary: []
    };
  },
  methods: {

    goBack() {
      this.$router.push({ name: 'settings' });
    },
    submitContact() {
      const data = {
        contacts: this.contacts.map(contact => ({
          id: contact.id,
          value: contact.value
        }))
      };
      this.$store.dispatch('admin/settings/contacts/update', data);
    },
    submitSocial() {
      const data = {
        contacts: this.socials.map(contact => ({
          id: contact.id,
          value: contact.value
        }))
      };
      this.$store.dispatch('admin/settings/contacts/update', data);
    },
    updateBoundaryData(boundary) {
      this.temp_boundary = boundary;

    },
    updateBoundary() {

      if (this.temp_boundary.length === 0) return;

      const data = {
        boundary: this.temp_boundary
      };
      this.$store.dispatch('admin/settings/contacts/updateBoundary', data);
    }
  },
  computed: {
    ...mapGetters({
      getContacts: 'admin/settings/contacts/getContacts',
      getSocials: 'admin/settings/contacts/getSocials',
      getBoundary: 'admin/settings/contacts/getBoundary'
    })
  },
  async created() {
    await this.$store.dispatch('admin/settings/contacts/get');
    this.contacts = this.getContacts;
    this.socials = this.getSocials;
    this.boundary = this.getBoundary ? this.getBoundary : null;
  }
};
</script>

